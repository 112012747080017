@use "@angular/material" as mat;
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "app/styles/icons.scss";
@import "app/styles/roboto.scss";
@import "~quill/dist/quill.snow.css";
@import "@sweetalert2/theme-dark/dark.scss";

@include mat.core();

.mat-horizontal-content-container {
  padding: 0px !important;
}

.full-screen-modal .mat-dialog-container {
  max-width: none;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #000 !important;
  font-weight: bold;
}

$md-blue: (
  50: #e7eaf4,
  100: #c4cbe4,
  200: #9da9d2,
  300: #7587bf,
  400: #586db2,
  500: #3a53a4,
  600: #344c9c,
  700: #2c4292,
  800: #253989,
  900: #182978,
  A100: #b1bdff,
  A200: #7e91ff,
  A400: #4b66ff,
  A700: #3151ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-red: (
  50: #f6e4e4,
  100: #e9bbbb,
  200: #db8e8e,
  300: #cd6060,
  400: #c23e3e,
  500: #b71c1c,
  600: #b01919,
  700: #a71414,
  800: #9f1111,
  900: #900909,
  A100: #ffbebe,
  A200: #ff8b8b,
  A400: #ff5858,
  A700: #ff3f3f,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

$md-yellow: (
  50: #fff8e1,
  100: #ffecb5,
  200: #ffe083,
  300: #ffd451,
  400: #ffca2c,
  500: #ffc107,
  600: #ffbb06,
  700: #ffb305,
  800: #ffab04,
  900: #ff9e02,
  A100: #ffffff,
  A200: #fffaf2,
  A400: #ffe4bf,
  A700: #ffd9a6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-accent: (
  50: #e9e9eb,
  100: #c7c8cc,
  200: #a2a3aa,
  300: #7d7e88,
  400: #61626f,
  500: #454655,
  600: #3e3f4e,
  700: #363744,
  800: #2e2f3b,
  900: #1f202a,
  A100: #7482ff,
  A200: #4154ff,
  A400: #0e26ff,
  A700: #0018f3,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-dark: (
  50: #e4e4e5,
  100: #bcbcbf,
  200: #8f9095,
  300: #62636a,
  400: #41414a,
  500: #1f202a,
  600: #1b1c25,
  700: #17181f,
  800: #121319,
  900: #0a0b0f,
  A100: #548dff,
  A200: #216bff,
  A400: #004fed,
  A700: #0047d4,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

.mat-white {
  color: #fff;
}

$primary: mat.define-palette($md-blue, 500);
$accent: mat.define-palette($md-accent, 600);
$warn: mat.define-palette($md-red, 500);

$primaryRed: mat.define-palette($md-yellow, 500);

$primaryDark: mat.define-palette($md-dark, 500);
$warnDark: mat.define-palette($md-red, 500);

$standardTheme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$redTheme: mat.define-light-theme(
  (
    color: (
      primary: $primaryRed,
      accent: $accent,
      warn: $warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$darkTheme: mat.define-dark-theme(
  (
    color: (
      primary: $primaryDark,
      accent: $accent,
      warn: $warnDark,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$rotDarkTheme: mat.define-dark-theme(
  (
    color: (
      primary: $primaryRed,
      accent: $accent,
      warn: $warnDark,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

ngx-material-timepicker-container {
  --background: #3a53a4 !important;
  --dial-background-color: #3a53a4 !important;
  --button-color: #3a53a4 !important;
  --dial-editable-active-color: #3a53a4 !important;
  --clock-hand-color: #3a53a4 !important;
}

@mixin darkTheme() {
  .mat-input-element:disabled {
    color: #fff !important;
  }

  .standard-dark-theme {
    .mat-toolbar.mat-primary {
      background: #3a53a4;
      color: #fff;
    }
  }

  .swal2-toast {
    background: #333 !important;
    color: #fff !important;
  }

  .rot-dark-theme {
    .mat-toolbar.mat-primary {
      background: #b01919;
      color: #fff;
    }
  }

  .nameTag {
    color: #fff !important;
  }

  --mdc-theme-primary: #fff;

  .standard-dark-theme
    .mdc-text-field--focused:not(.mdc-text-field--disabled)
    .mdc-floating-label,
  .rot-dark-theme
    .mdc-text-field--focused:not(.mdc-text-field--disabled)
    .mdc-floating-label {
    color: rgba(255, 255, 255, 0.9);
  }

  .standard-dark-theme,
  .rot-dark-theme {
    .mdc-text-field--outlined:not(
        .mdc-text-field--disabled
      ).mdc-text-field--focused
      .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(
        .mdc-text-field--disabled
      ).mdc-text-field--focused
      .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(
        .mdc-text-field--disabled
      ).mdc-text-field--focused
      .mdc-notched-outline__trailing {
      border-color: rgba(255, 255, 255, 0.9);
    }

    .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
    .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
      background: #ddd;
    }

    .mdc-text-field .mdc-text-field__input {
      caret-color: rgba(255, 255, 255, 0.7);
    }

    .mat-primary
      .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
      .mdc-list-item__primary-text {
      color: rgba(255, 255, 255, 0.9);
    }

    .mat-progress-spinner.light circle,
    .mat-spinner.light circle {
      stroke: #fff !important;
    }

    .notificationMessageBox {
      background: #333 !important;
    }

    .mat-chip.mat-standard-chip .mat-chip-remove {
      color: #b71c1c;
      opacity: 1;
    }
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: red !important;
  }

  .table-info {
    background: none !important;
    color: #fff;
  }

  table {
    color: #fff !important;
    --bs-table-bg: #424242 !important;
  }

  quill-editor,
  quill-editor .ql-editor.ql-blank::before,
  .ql-toolbar button,
  .ql-toolbar .ql-header {
    color: #fff !important;
  }

  .ql-picker-options {
    background: #424242 !important;
  }

  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: #b2b1b1 !important;
  }

  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button:focus .ql-stroke,
  .ql-snow .ql-toolbar button:focus .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: #b2b1b1 !important;
  }

  .ql-snow.ql-toolbar button:hover .ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: #b2b1b1 !important;
  }

  .ql-toolbar .ql-stroke {
    stroke: #fff;
  }
  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: #fff !important;
  }

  mat-card-content {
    color: #fff;
  }

  .dark,
  .dark label,
  .dark .mat-form-field-outline,
  .speech-bubble,
  .md-editor-container mat-icon,
  .md-editor-container .preview-panel {
    color: #000 !important;
  }

  .mat-row:hover {
    background: #333 !important;
  }

  input:-webkit-autofill {
    -webkit-transition-delay: 99999s;
    transition-delay: 99999s;
  }

  .bright {
    color: #fff !important;
  }

  hr {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  html {
    --scrollbarBG: rgba(255, 255, 255, 0.5) !important;
    --thumbBG: rgba(0, 0, 0, 0.6) !important;
  }

  .overlay {
    background: #212121 !important;
  }

  .closeButtonDialog {
    color: #fff;
  }

  image-cropper .overlay {
    background: transparent !important;
  }

  .tippy-box {
    background-color: #212121 !important;
    color: #fff !important;
  }

  .sticky,
  .footer {
    background-color: #424242 !important;
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #fff !important;
  }

  .centerToNav a {
    color: #fff;
  }

  .mat-form-field-appearance-outline.mat-form-field-disabled
    .mat-form-field-outline {
    color: rgba(255, 255, 255, 0.3);
  }

  .mat-form-field-appearance-outline.mat-form-field-disabled
    .mat-form-field-label {
    color: rgba(255, 255, 255, 0.7);
  }

  .mat-select-disabled .mat-select-value,
  .mat-input-element:disabled {
    color: rgba(255, 255, 255, 1);
  }

  .bgwarning {
    color: #000;
  }

  .clickable {
    cursor: pointer;
  }

  .clickable:hover {
    opacity: 0.7;
  }

  .mat-button-toggle-disabled.mat-button-toggle-checked {
    background-color: #ffc107;
    cursor: default !important;
  }

  .mat-button-toggle-disabled {
    background-color: #424242;
    cursor: default !important;
  }

  .mat-date-range-input-inner[disabled],
  .mat-form-field-disabled .mat-date-range-input-separator {
    color: rgba(255, 255, 255, 1);
    cursor: default !important;
  }

  .mat-date-range-input {
    margin-top: 5px;
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    background-color: #ffc107;
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: #000;
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
  .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active
    .mdc-tab__text-label {
    color: #fff;
  }

  .mdc-tab__ripple::before,
  .mat-mdc-tab .mat-ripple-element,
  .mat-mdc-tab-header-pagination .mat-ripple-element,
  .mat-mdc-tab-link .mat-ripple-element {
    background-color: #fff;
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled)
    .mdc-tab-indicator__content--underline,
  .mat-mdc-tab-link:not(.mat-mdc-tab-disabled)
    .mdc-tab-indicator__content--underline {
    border-color: #fff;
  }

  /**
  * Sweet Alert Dark Theme
  */
  .swal2-popup {
    background: #19191a;
    color: #e1e1e1;
    border: 1px solid #242425;
  }

  .swal2-title,
  .swal2-html-container {
    color: #e1e1e1;
  }

  /**
  * Skeleton Loader
  */
  ngx-skeleton-loader .skeleton-loader {
    background: #555555 !important;
  }

  ngx-skeleton-loader .progress:before {
    background-image: linear-gradient(
      90deg,
      hsla(0, 0%, 90%, 0),
      hsla(0, 0%, 60%, 0.6),
      hsla(0, 0%, 100%, 0)
    ) !important;
  }

  .orgaType.mat-button-toggle-disabled {
    color: white !important;
  }

  .shiftIcon {
    color: #fff !important;
  }

  .cdk-drag-preview {
    background-color: #333;
  }

  .badge-light {
    color: #fff;
  }

  .mdc-list-item.mdc-list-item--with-trailing-meta .mdc-list-item__end {
    color: #fff !important;
  }
}

@mixin primaryTheme() {
  .mat-toolbar.mat-primary {
    background: #b01919;
    color: #fff;
  }
}

//Requierd for Browser without prefers-color-scheme support
// Apply theme for default Angular Material components
@include mat.all-legacy-component-themes($standardTheme);

.standard-dark-theme {
  --primary: #1f202a;
  --accent: #3e3f4e;
  --warn: #b01919;
  --bs-link-color-rgb: 49, 220, 235;
  --bs-link-hover-color-rgb: 49, 220, 200;

  .mat-mdc-dialog-container {
    --mdc-dialog-supporting-text-color: #fff !important;
  }

  @include mat.core-color($darkTheme);
  @include mat.all-component-themes($darkTheme);
  @include darkTheme();
}

.rot-dark-theme {
  --primary: #ffc107;
  --accent: #3e3f4e;
  --warn: #b01919;
  --bs-link-color-rgb: 255, 193, 7;
  --bs-link-hover-color-rgb: 210, 170, 7;

  .mat-mdc-dialog-container {
    --mdc-dialog-supporting-text-color: #fff !important;
  }

  @include mat.core-color($rotDarkTheme);
  @include mat.all-component-themes($rotDarkTheme);
  @include darkTheme();
}

.rot-theme {
  --primary: #ffc107;
  --accent: #3e3f4e;
  --warn: #b01919;

  .mat-mdc-dialog-container {
    --mdc-dialog-supporting-text-color: #000 !important;
  }

  @include mat.core-color($redTheme);
  @include mat.all-component-themes($redTheme);
  @include primaryTheme();
}

.standard-theme {
  --primary: #3a53a4;
  --accent: #3e3f4e;
  --warn: #b01919;

  .mat-mdc-dialog-container {
    --mdc-dialog-supporting-text-color: #000 !important;
  }

  @include mat.core-color($standardTheme);
  @include mat.all-component-themes($standardTheme);
}

html {
  --scrollbarBG: rgba(207, 216, 220, 0.4);
  --thumbBG: rgba(144, 164, 174, 0.4);
}

body {
  background: #fafafa;
}

a {
  text-decoration: none;
}

:focus {
  outline: none !important;
}

.mat-sidenav-content::-webkit-scrollbar {
  width: 11px;
}

.mat-sidenav-content {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG) !important;
}

.cdk-overlay-dark-backdrop,
.rot-dark-theme .mat-drawer-backdrop.mat-drawer-shown,
.standard-dark-theme .mat-drawer-backdrop.mat-drawer-shown,
.swal2-container {
  backdrop-filter: blur(3px);
}

.mat-sidenav-content::-webkit-scrollbar-track {
  background: var(--scrollbarBG) !important;
}

.mat-sidenav-content::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.pageBack {
  position: absolute;
  top: -35px;
  left: -10px;
  margin-bottom: -50px;
}

.pageNext {
  position: absolute;
  top: -35px;
  right: -10px;
  margin-bottom: -50px !important;
  float: right;
}

.pageBack:hover,
.pageNext:hover {
  color: #ddd !important;
}

.closeButton {
  position: absolute !important;
  top: 5px;
  right: 5px;
  width: 20px;
}

.closeButton:hover {
  color: #ddd !important;
}

.mat-success {
  color: #fff;
}

.cal-week-view .cal-time {
  width: 50px !important;
}

.cal-week-view .cal-time-label-column {
  width: 50px !important;
}

.cal-week-view .cal-day-headers {
  padding-left: 50px !important;
}

.mat-row:hover {
  background-color: rgb(202, 202, 202);
}

.mat-sidenav-content {
  padding-bottom: 60px; // push your whole site same height upwards. ;)
}

.mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: #fff;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #fff !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #28a745 !important;
}

.sticky,
.footer {
  background-color: #fff;
}

.tippy-box {
  background-color: #fff;
  color: #000;
}

.light-theme
  .mat-form-field-appearance-outline.mat-form-field-disabled
  .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}

.light-theme
  .mat-form-field-appearance-outline.mat-form-field-disabled
  .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.light-theme .mat-select-disabled .mat-select-value,
.light-theme .mat-input-element:disabled {
  color: rgba(0, 0, 0, 1);
}

.light-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: #b71c1c;
  opacity: 1;
}

.aligned-with-icon {
  position: absolute;
  margin-top: 0px;
  margin-left: 5px;
  /* optional */
}

.mat-button-toggle-group {
  flex-wrap: wrap;
}

.mat-header-cell {
  font-size: medium;
}

/**
Spin animation
*/
.md-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.md-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

.mdc-floating-label {
  overflow: visible !important;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/**
Sweet Alert
*/
.swal2-styled.swal2-confirm {
  background-color: #28a745 !important;
}

.swal2-styled.swal2-deny {
  background-color: #b71c1c !important;
}

.swal2-styled.primaryColor.swal2-confirm {
  background-color: #ffc107 !important;
  color: #000 !important;
}

.mat-primary:active:focus {
  box-shadow: 0 0 0 0.25rem rgb(217 164 6 / 50%);
}

.mat-success:active:focus {
  box-shadow: 0 0 0 0.25rem rgb(60 153 110 / 50%);
}

.mat-warn:active:focus {
  box-shadow: 0 0 0 0.25rem rgb(225 83 97 / 50%);
}

.bg-success {
  background-color: #28a745 !important;
}

.bg-warn {
  background-color: #b71c1c !important;
}

.bg-primary {
  background-color: #ffc107 !important;
}

.shiftIcon {
  color: #000;
}

/**
Boostrap Responder & EvD Plan
*/

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.matDatepickerNoHeader.mat-calendar {
  height: unset !important;
}

.matDatepickerNoHeader .mat-calendar-header {
  display: none;
}

.matDatepickerNoHeader .mat-calendar-body-label {
  display: none !important;
}

.cdk-overlay-container {
  z-index: 900 !important;
}

.noTop .mat-form-field-prefix {
  top: unset !important;
}

.course-panel {
  min-height: 200px;
}

.ngx-mat-timeline-item-icon {
  line-height: 50px;
  color: #fff;
  background: var(--bs-primary);
  width: 50px !important;
  text-align: center;
  height: 50px !important;
}

.ngx-mat-timeline-item-fill {
  flex: 0 !important;
}

.ngx-mat-timeline-item {
  gap: 10px !important;
}

.ngx-mat-timeline-vertical
  > .ngx-mat-timeline-content
  .ngx-mat-timeline-item:not(:last-child) {
  margin-bottom: 0px !important;
}

.badge-light {
  color: #000;
}

.mdc-list-item.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: #000 !important;
}

quill-editor {
  width: 100%;

  .ql-container {
    min-height: 200px;
  }
}
