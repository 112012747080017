/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100vh;
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

.atlwdg-trigger {
  z-index: 4000;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.input-full-width {
  width: 100%;
}

.body-section {
  margin-top: 20px;
}

.section-header {
  margin: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.section-header:after {
  content: "";
  /* border-top: 1px solid rgba(0,0,0,.1); */
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 30px;
  flex: 1 0 20px;
}

.swal2-container {
  backdrop-filter: none !important;
  -webkit-backdrop-filter: none !important;
}

.mat-drawer-content {
  overflow-x: hidden !important;
}

.mat-card-header-text {
  margin: 0px !important;
}

.mat-success {
  background-color: #28a745 !important;
}

.mat-green {
  color: #28a745 !important;
}

.mat-red {
  color: #b71c1c !important;
}

.mat-yellow {
  color: #fed03d !important;
}

.nav-caption {
  margin-left: 10px;
}

.mat-raised-button:hover {
  text-decoration: none;
}

.mat-dialog-actions {
  display: block !important;
}

.mat-expansion-panel-header {
  padding-left: 12px !important;
}

.mat-expansion-panel-body {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.mat-tooltip {
  font-size: 14px !important;
}

.pagerLastAlarm.mat-tooltip {
  width: 60%;
}

.expansion-header h6 {
  padding: 0px;
  margin: 0px;
}

.expansion-header .mat-expansion-panel-header-description {
  justify-content: flex-end;
}

label {
  margin-bottom: 0px !important;
}

simple-snack-bar span {
  margin: auto;
  text-align: center;
}

.mat-sort-header-container {
  font-size: medium;
}

.bgsuccess {
  background-color: #28a745 !important;
}

.bgerror {
  background-color: #b71c1c !important;
}

.bgwarning {
  background-color: #fed03d !important;
}

.pinnedDrawer .offset-lg-2 {
  margin-left: 5%;
}

.pinnedDrawer .col-lg-8 {
  max-width: 90%;
  flex: 0 0 90%;
}

.pinnedDrawer mat-card .col-lg-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

mat-form-field {
  padding-top: 5px;
}

.mat-form-field-label {
  z-index: 1000;
  background-color: #ffffff;
  width: auto !important;
  padding: 0 30px;
}

mat-bottom-sheet-container {
  padding-bottom: 20px !important;
}

.mat-tab-label {
  opacity: 1 !important;
}

.pointer {
  cursor: pointer;
}

.defaultCursor {
  cursor: default !important;
}

@media screen and (max-width: 600px) {

  th.mat-header-cell,
  td.mat-cell {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  th.mat-header-cell:last-of-type,
  td.mat-cell:last-of-type {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.mat-select-panel {
  max-height: 300px !important;
}

.text-bold {
  font-weight: bold;
}

.menu-button {
  height: auto !important;
  padding: 13px 15px !important;

  mat-icon {
    scale: 1.5;
    padding-right: 35px !important;
  }
}

.loading-spinner-center {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  scale: 0.8;
}

.submit-button circle {
  stroke: #fff !important;
}

.standard-theme .mdc-list-item__secondary-text,
.standard-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.standard-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 1)) !important;
}

.mat-mdc-form-field-icon-suffix {
  margin-right: 10px;
}

.mat-error,
mat-error {
  font-size: 75%;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0 8px 0 8px !important;
}

.rot-theme .mat-mdc-slide-toggle,
.standard-dark-theme .mat-mdc-slide-toggle,
.red-dark-theme .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #28a745 !important;
  --mdc-switch-selected-hover-state-layer-color: #28a745 !important;
  --mdc-switch-selected-pressed-state-layer-color: #28a745 !important;
  --mdc-switch-selected-handle-color: #28a745 !important;
  --mdc-switch-selected-focus-handle-color: #28a745 !important;
  --mdc-switch-selected-hover-handle-color: #28a745 !important;
  --mdc-switch-selected-pressed-handle-color: #28a745 !important;
}

.standard-dark-theme .table>:not(caption)>*>*,
.red-dark-theme .table>:not(caption)>*>* {
  color: #fff !important;
}


.mat-mdc-slide-toggle .mdc-switch {

  &:enabled .mdc-switch__track::after,
  &.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background-color: #22bb33 !important;
  }

  &.mdc-switch--selected:enabled .mdc-switch__icon {
    fill: #fff !important;
  }
}

mat-list-item span {
  text-wrap: wrap;
}